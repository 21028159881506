import { Link } from 'gatsby';
import React from 'react';
import { darken } from 'polished';
import { css } from '@emotion/react';

import { colors } from '../styles/colors';

export interface PaginationProps {
  currentPage: number;
  numPages: number;
}

const Pagination: React.FunctionComponent<PaginationProps> = ({ currentPage, numPages }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/' : '/' + (currentPage - 1).toString();
  const nextPage = '/' + (currentPage + 1).toString();

  return (
    <nav css={navCss}>
      <div>
        {!isFirst && (
          <Link to={prevPage} rel="prev">
            ← Newer Posts
          </Link>
        )}

        {"Page " + currentPage + " of " + numPages}

        {!isLast && (
          <Link to={nextPage} rel="next">
            Older Posts →
          </Link>
        )}
      </div>
    </nav>
  );
};

const navCss = css`
  text-align: center;
  div {
    display: inline-block;
    padding-bottom: 100px;
  }

  a {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell;
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    margin: 0 4px;
    margin-bottom: 5px;
    min-width: 50px;

    // &:hover:not(.active) {
    //   background-color: #ddd;
    // }

    // &:hover {
    //   text-decoration: none;
    // }
  }
`;

export default Pagination;
